import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet'


// Icons
import { FaWind, FaTv, FaWifi, FaBath, FaUtensils, FaUserAlt } from 'react-icons/fa';

function htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function Page ({pageContext}) {
    
    return(
        <Layout>
            <section id="testimonials" className="py-5 lg:py-10">
            <Helmet title="Halaman Tidak ditemukan" defer={false} />
            <div className="container mx-5 w-11/12 md:mx-auto text-center">
                <h1>Halaman Tidak Ditemukan</h1>
                <a href="https://www.marajuara.web.id">Kembali ke halaman utama</a>
            </div>
            </section>
        </Layout>
    );
}

export default Page;